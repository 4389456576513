@charset "UTF-8";
header {
  overflow: hidden;
  width: 100%;
  position: relative; }

.main-nav {
  width: 100%;
  margin: 8px auto;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: right;
  font-size: smaller; }
  @media screen and (min-width: 768px) {
    .main-nav {
      margin: 10px auto; } }
  @media screen and (min-width: 1024px) {
    .main-nav {
      margin: 15px auto; } }
  .main-nav ul {
    display: inline-table;
    max-width: 100%;
    margin: 0 15px;
    padding: 0; }
  .main-nav li {
    list-style: none;
    display: table-cell;
    padding: 4px 8px; }
  .main-nav li a {
    color: #03060c; }
    .main-nav li a:hover {
      color: #11244a; }

.list:before {
  content: '';
  width: 16px;
  height: 64px;
  display: block;
  position: absolute;
  left: 0;
  background: linear-gradient(#eaf4fc, #fffffc);
  background: linear-gradient(to right, #eaf4fc 0%, rgba(255, 255, 255, 0) 100%); }

.list:after {
  content: '';
  width: 16px;
  height: 64px;
  display: block;
  position: absolute;
  right: 0;
  background: linear-gradient(#eaf4fc, #fffffc);
  background: linear-gradient(to left, #eaf4fc 0%, rgba(255, 255, 255, 0) 100%); }

*, *::before, *::after {
  box-sizing: inherit; }

html {
  box-sizing: border-box; }

a {
  text-decoration: none;
  color: #162e5f; }
  a:hover {
    color: #03060c; }

body {
  font-family: "Hiragino Maru Gothic W4 JIS2004", "\6E38\30B4\30B7\30C3\30AF", YuGothic, "\30D2\30E9\30AE\30CE\89D2\30B4   ProN W3", "Hiragino Kaku Gothic ProN", "Yu Gothic", 游ゴシック体, "\30D2\30E9\30AE\30CE\89D2\30B4   Pro W3", "Hiragino Kaku Gothic Pro", "\30E1\30A4\30EA\30AA", Meiryo, "Helvetica Neue", Helvetica, Arial, "Avenir Next", Quicksand, sans-serif;
  font-weight: 500;
  font-size: 1.0em;
  color: #404040;
  background: linear-gradient(#eaf4fc, #fffffc);
  background-size: cover;
  border: 5px solid #162e5f; }
  @media screen and (min-width: 768px) {
    body {
      border: 10px solid #162e5f; } }
  @media screen and (min-width: 1024px) {
    body {
      border: 15px solid #162e5f; } }

header {
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  overflow: hidden;
  width: 100%;
  color: gray; }

h1 {
  margin: 1ex 0;
  font-size: 1.25em;
  padding-left: 0; }

.header-logo {
  text-align: center;
  margin: 1em;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (min-width: 480px) {
    .header-logo {
      height: 250px; } }
  @media screen and (min-width: 768px) {
    .header-logo {
      height: 300px; } }
  .header-logo img {
    max-width: 50%;
    max-height: 260px;
    width: auto;
    height: auto; }

#main-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif; }

h2, h3 {
  font-family: Superclarendon, AvenirNextCondensed, AvenirNext, "Helvetica Neue", "Helvetica", "Arial", "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  font-weight: bold;
  font-style: normal;
  color: #646464;
  text-rendering: optimizeLegibility; }

main {
  position: relative;
  margin: 0 auto;
  padding: 1em 0; }
  main h2 {
    border-bottom: 1px solid #48a;
    padding: 17px 0 17px 45px; }
    @media screen and (min-width: 768px) {
      main h2 {
        padding: 17px 0 17px 10px; } }
  main h3 {
    border-left: 3px solid #3886b8;
    margin: 32px 0 16px 0;
    padding: 7px 0 7px 45px; }
    @media screen and (min-width: 768px) {
      main h3 {
        padding: 7px 0 7px 10px; } }
  main h4 {
    margin: 16px 0 8px 0;
    padding: 7px 0 7px 45px;
    font-size: normal;
    position: relative;
    display: inline-block;
    top: 0;
    overflow: hidden; }
    @media screen and (min-width: 480px) {
      main h4 {
        padding: 7px 0 7px 10px; } }
    main h4:before {
      content: "";
      position: absolute;
      bottom: 0;
      height: 0;
      width: 100%;
      border-bottom: 1px solid #3886b8;
      z-index: 0; }
  main p, main ul {
    margin: 0 48px; }
    @media screen and (min-width: 768px) {
      main p, main ul {
        margin: 0 13px; } }
  main p {
    margin-top: 1em; }
  main ul {
    list-style-type: disc; }

footer {
  margin-left: auto;
  margin-right: auto;
  max-width: 1024px;
  width: 100%;
  padding-top: 1.7em;
  padding-bottom: 1.7em;
  font-size: smaller;
  text-align: center;
  line-height: 2em; }

.bg-main {
  background-color: #162e5f; }

.bg-dark {
  background-color: #404040; }
  .bg-dark h1, .bg-dark h2, .bg-dark h3 {
    color: white; }
  .bg-dark a {
    color: #e6e6e6; }
    .bg-dark a:hover {
      color: #f2f2f2; }
  .bg-dark li {
    list-style: none; }
  .bg-dark li::before {
    content: "\25CF   ";
    color: white; }

.not-center p, ul {
  text-align: left; }

figure {
  margin: auto; }

.person-picture img {
  margin: 0 auto;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  text-align: center; }

.person-picture img {
  width: 150px;
  height: 150px;
  border-radius: 150px;
  background-size: 150px;
  object-fit: cover; }

@media screen and (min-width: 768px) {
  .person-picture img {
    width: 200px;
    height: 200px;
    border-radius: 200px;
    background-size: 200px; } }

@media screen and (min-width: 1024px) {
  .person-picture img {
    width: 250px;
    height: 250px;
    border-radius: 250px;
    background-size: 250px; } }

.person-picture-s img {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-size: 32px;
  object-fit: cover; }

@media screen and (min-width: 768px) {
  .person-picture-s img {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-size: 48px; } }

@media screen and (min-width: 1024px) {
  .person-picture-s img {
    width: 64px;
    height: 64px;
    border-radius: 64px;
    background-size: 64px; } }

.social-links {
  display: flex;
  justify-content: center;
  padding: 0; }
  .social-links li {
    list-style: none;
    margin: 0 4px; }
  .social-links .github a {
    color: #666; }
  .social-links .twitter a {
    color: #1DA1F2; }

.hero {
  position: relative; }

.hero-title {
  margin: 0;
  font-size: 1.5em;
  text-align: center; }
  @media screen and (min-width: 768px) {
    .hero-title {
      font-size: 2.5em; } }
  @media screen and (min-width: 1024px) {
    .hero-title {
      font-size: 3.2em; } }

.non-smil-hero-title {
  color: #162e5f;
  padding: 3em 0; }

.hero-subtitle {
  font-size: larger;
  font-weight: bold; }

.block {
  padding: 3em 0;
  padding-left: 13px;
  padding-right: 13px;
  text-align: center;
  line-height: 2.0em; }
  @media screen and (min-width: 768px) {
    .block {
      padding-left: 128px;
      padding-right: 128px; } }
  @media screen and (min-width: 1024px) {
    .block {
      padding-left: 180px;
      padding-right: 180px; } }

.patrons {
  color: white;
  background-color: #24B5A6; }
  .patrons h2 {
    color: white; }
  .patrons a {
    color: #204288; }

.patron img {
  max-width: 100%;
  width: 100%; }

.patron h3 {
  color: white; }

.patron-image-container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 222px;
  height: 222px;
  padding: 10px; }
  @media screen and (min-width: 480px) {
    .patron-image-container {
      width: 300px;
      height: 300px; } }

.personal-patron .patron-image-container {
  width: 99px;
  height: 99px;
  padding: 5px; }
  @media screen and (min-width: 480px) {
    .personal-patron .patron-image-container {
      width: 134px;
      height: 134px; } }

.schedule-item {
  margin-top: 2em;
  text-align: left;
  display: flex;
  flex-direction: row; }

.schedule-time {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", sans-serif;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  flex-direction: column; }

.schedule-time-begin {
  font-size: smaller; }

.schedule-time-end {
  font-size: smaller;
  color: #aaa; }

.bar {
  background-color: #24B5A6;
  flex: 0 0 6px;
  margin: 0 18px;
  border-radius: 3px; }

.break .bar {
  background-color: #f0b5d9; }

.talk .type {
  font-size: small;
  line-height: 1;
  display: inline-block;
  font-weight: bold;
  color: white;
  padding: 4px 10px;
  margin-right: 6px;
  background-color: #162e5f;
  border-radius: 12px;
  vertical-align: middle; }

.talk h2.title {
  display: inline;
  font-family: "Hiragino Maru Gothic W4 JIS2004", YuGothic, "Hiragino Kaku Gothic ProN", "Yu Gothic", 游ゴシック体, "Hiragino Kaku Gothic Pro", Meiryo, "Helvetica Neue", Helvetica, Arial, "Avenir Next", Quicksand, sans-serif;
  font-size: 1.0em;
  font-weight: bold;
  vertical-align: middle; }

.talk .speaker-name {
  vertical-align: middle; }

.talk figure {
  display: inline-block;
  vertical-align: middle;
  margin-top: 8px;
  margin-right: 8px; }

.talk .elevator-pitch {
  font-size: small;
  line-height: 1.6; }

.talk .detail {
  font-size: small; }

.talk .target {
  color: #888;
  margin-right: 1em; }

.speaker {
  padding: 3em 0;
  padding-left: 13px;
  padding-right: 13px;
  margin: 0 auto; }
  @media screen and (min-width: 480px) {
    .speaker {
      padding-left: 26px;
      padding-right: 26px;
      max-width: 716px; } }
  @media screen and (min-width: 768px) {
    .speaker {
      padding-left: 64px;
      padding-right: 64px;
      max-width: 768px; } }
  @media screen and (min-width: 1024px) {
    .speaker {
      padding-left: 0;
      padding-right: 0;
      max-width: 880px; } }
  .speaker h1, .speaker h2 {
    margin: 0;
    padding: 1em 0;
    line-height: 1;
    font-family: system-ui, -apple-system, sans-serif;
    text-align: center; }
  .speaker h1 {
    font-size: 3rem; }
    @media screen and (min-width: 768px) {
      .speaker h1 {
        font-size: 4.5rem; } }
    @media screen and (min-width: 1024px) {
      .speaker h1 {
        font-size: 6rem; } }
  @media screen and (min-width: 768px) {
    .speaker h2 {
      font-size: 1.5rem; } }
  @media screen and (min-width: 1024px) {
    .speaker h2 {
      font-size: 2rem; } }
  .speaker figure {
    text-align: center; }
  .speaker .elevator-pitch, .speaker .description {
    margin: 3rem 0;
    font-size: 0.9em; }
    @media screen and (min-width: 480px) {
      .speaker .elevator-pitch, .speaker .description {
        font-size: 1em; } }
  .speaker .elevator-pitch {
    line-height: 1.8; }
  .speaker .description {
    line-height: 1.8; }
  .speaker .profile {
    display: flex;
    flex-direction: row;
    margin-top: 2.5em;
    font-size: smaller; }
    .speaker .profile .avatar-box {
      margin-right: 10px; }
    .speaker .profile .name-box {
      padding-bottom: 4px; }
  .speaker .name {
    font-weight: bold; }

.remarkable-button {
  display: inline-block;
  font-weight: bold;
  color: #162e5f;
  padding: 9px 16px;
  margin: 0 auto;
  border: 2px solid #162e5f;
  border-radius: 20px; }
  @media screen and (min-width: 768px) {
    .remarkable-button {
      font-size: x-large;
      padding: 12px 20px;
      border: 4px solid #162e5f;
      border-radius: 30px; } }
  @media screen and (min-width: 1024px) {
    .remarkable-button {
      font-size: xx-large;
      padding: 18px 30px;
      border-radius: 40px; } }

.registration-button {
  position: fixed;
  right: 1.3em;
  bottom: 44px;
  bottom: calc(env(safe-area-inset-bottom) + 44px);
  transform-style: preserve-3d;
  perspective: 500px; }
  @media screen and (min-width: 768px) {
    .registration-button {
      right: 2.5em;
      bottom: 2em; } }
  @media screen and (min-width: 1024px) {
    .registration-button {
      right: 2.5em;
      bottom: 3em; } }

.registration-inner {
  display: inline-block;
  font-weight: bold;
  color: white;
  padding: 9px 16px;
  background-color: #162e5f;
  border-radius: 20px; }
  @media screen and (min-width: 768px) {
    .registration-inner {
      font-size: x-large;
      padding: 12px 20px;
      border-radius: 30px; } }
  @media screen and (min-width: 1024px) {
    .registration-inner {
      font-size: xx-large;
      padding: 18px 30px;
      border-radius: 40px; } }

.registration-inner-animation {
  transform-origin: 0 16px -16px;
  transform: rotateX(-89deg);
  animation: registrationButtonAnimation 700ms ease-in-out 3s 1 reverse both; }

@keyframes registrationButtonAnimation {
  0% {
    transform: rotateX(0deg);
    opacity: 1; }
  70% {
    opacity: 1; }
  100% {
    transform: rotateX(-89deg);
    opacity: 0; } }

.general-info {
  margin: 0 10px;
  padding: 1em;
  background-color: #E6EDFA;
  border-radius: 8px;
  text-align: left; }
  @media screen and (min-width: 768px) {
    .general-info {
      margin: 0 2em; } }
  @media screen and (min-width: 1024px) {
    .general-info {
      margin: 0 3em; } }
  .general-info h2 {
    text-align: center;
    font-size: larger; }
  .general-info h2, .general-info h3, .general-info h4 {
    margin: 0;
    padding: 0; }
  .general-info p {
    font-size: smaller;
    line-height: 1.8; }
    .general-info p:last-child {
      margin-bottom: 0; }

.event-info {
  max-width: 70vw;
  margin: 0 auto;
  padding: 0;
  text-align: start;
  font-size: 3vw;
  font-weight: bold;
  line-height: 1.2; }
  @media screen and (min-width: 480px) {
    .event-info {
      max-width: 44vw; } }
  @media screen and (min-width: 768px) {
    .event-info {
      max-width: 44vw; } }
  @media screen and (min-width: 1024px) {
    .event-info {
      max-width: 45vw; } }
  .event-info .date {
    font-size: 3.5vw; }
  .event-info .date-number-s {
    font-family: sans-serif;
    font-size: 4vw;
    font-weight: bold; }
  .event-info .date-number {
    font-family: Futura-CondensedExtraBold, Quicksand, sans-serif;
    font-size: 8vw; }
  .event-info .date-day-of-week {
    font-weight: bold;
    color: #24B5A6; }
  .event-info .theme {
    font-family: serif;
    font-size: larger;
    font-weight: normal;
    line-height: 7vw; }
  .event-info a, .event-info h2 {
    color: #444; }
  .event-info h2 {
    font-family: Futura-Condensed, Quicksand, sans-serif;
    font-size: 1.2rem; }
  .event-info p {
    margin: 0.8em 0;
    line-height: 3vw; }
  .event-info ul {
    padding-left: 0;
    list-style: none;
    line-height: 4vw; }
  .event-info li {
    padding: 0; }

.general-info-vivid {
  max-width: 800px;
  margin: 0 auto;
  padding: 2px 16px;
  text-align: start;
  border: 3px solid #162e5f;
  border-radius: 12px; }
  @media screen and (min-width: 768px) {
    .general-info-vivid {
      padding: 10px 32px; } }
  .general-info-vivid h2 {
    display: table;
    margin: -20px auto 0 auto;
    padding: 4px 20px;
    text-align: center;
    color: #162e5f;
    background: linear-gradient(#eaf4fc, #fffffc);
    border: 2px solid #162e5f;
    border-radius: 12px; }
    @media screen and (min-width: 768px) {
      .general-info-vivid h2 {
        margin: -28px auto 0 auto; } }
  .general-info-vivid p:last-child {
    margin-bottom: 0.5em; }

.team {
  display: grid;
  gap: 4rem 3rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); }
  @media screen and (min-width: 768px) {
    .team {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); } }
  .team ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .team .team-member .member-position-and-name {
    display: flex;
    justify-content: center; }
  .team .team-member .member-type {
    font-weight: normal;
    margin-right: 8px; }
  .team .team-member .member-name {
    font-weight: bold;
    font-size: larger; }
