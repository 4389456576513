table {
    width: 100%;
    border-collapse: collapse; 
}
table, th, td {
    border: solid 1px #ccc;
}
.price {
    text-align: right;
    padding-left: 1rem;
    padding-right: 1rem;
}